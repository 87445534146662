import React, { useEffect, useState, useRef } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Form, Button, Image, Spinner, Alert } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import MainNav from '../components/MainNav';
import { debounce } from 'lodash';
import axios from 'axios';
import './Styles.css';
import { handleTokenAndPostRequest } from '../utils/utils';

function PropertySearchLive() {
  const { agent_slug: initial_agent_slug } = useParams();
  const agent_slug = initial_agent_slug || 'property-insights';
  const [agentDetails, setAgentDetails] = useState(null);
  const [email, setEmail] = useState('');
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [message, setMessage] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [selectKey, setSelectKey] = useState(0); 
  const navigate = useNavigate();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const param_token = params.get('token');

//   const selectRef = useRef(null);

//   useEffect(() => {
//     if (selectRef.current) {
//       selectRef.current.focus();
//     }
//   }, [email]);

  useEffect(() => {
    if (agentDetails) {
      document.title = `${agentDetails.agency_name_short} Property Report`;
      document.body.style.backgroundImage = `url(${agentDetails.landing_background_url})`;
      document.body.style.backgroundRepeat = 'no-repeat';
      document.body.style.backgroundPosition = 'center center';
      document.body.style.backgroundAttachment = 'fixed';
      document.body.style.backgroundSize = 'cover';
    }

    return () => {
      document.body.style.backgroundImage = null;
    };
  }, [agentDetails]);
  useEffect(() => {
    const fetchAgentDetails = async () => {
      const url = `${window.api_host}/agency`;
  
      try {
        const response = await handleTokenAndPostRequest(
          url,
          {
            agent_slug: agent_slug,
          },
          {
            'Content-Type': 'application/json',
          }
        );
  
        const data = response.data;
        setAgentDetails(data);
      } catch (error) {
        console.error('Error:', error);
      }
    };
  
    fetchAgentDetails();
  }, [agent_slug]);

  const loadProperties = debounce((inputValue, callback) => {
    axios.get(`${window.api_host}/property-search?q=${inputValue}`)
      .then(response => {
        const options = response.data.map(property => ({
          label: property.address_text,
          value: property.id,
          slug: property.slug
        }));
        callback(options);
      })
      .catch(error => {
        console.error('Error loading property options:', error);
        callback([]);
      });
  }, 500);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!selectedProperty) {
      setMessage('Please select a property to view the report.');
      return;
    }
    // redirect the user to the Property Page
    const property_id = selectedProperty.value;
    const slug = selectedProperty.slug;
    const agentSlug = agentDetails.landing_page_slug;
    
    navigate(`/property-profile/${slug}-${property_id}/${agentSlug}`);


  };

  if (!agentDetails) {
    return (
      <div style={{ 
        position: 'absolute', 
        top: '50%', 
        left: '50%', 
        transform: 'translate(-50%, -50%)' 
      }}>
        <Spinner animation="border" />
      </div>
    );
  }

  return (
    <div>
      <MainNav agentDetails={agentDetails} />

      <Container className="agent-page" style={{ maxWidth: '600px' , marginTop: '100px' }}>
        <Row className="justify-content-md-center">
        <Col md="auto">
          <h1>Do your own appraisal for your property!</h1>
          <p>Search for your property to do your own property appraisal and view detailed analysis with valuation, time on market, history and comparable property data.</p>
          </Col>
        </Row>
        <Form className="w-100" onSubmit={handleSubmit}>
          <Row className="mb-2">
              <Col>
                  <AsyncSelect
                      key={selectKey}
                      cacheOptions
                      defaultOptions
                      loadOptions={loadProperties}
                      onChange={setSelectedProperty}
                      value={selectedProperty}
                      noOptionsMessage={() => null}      
                      placeholder="Search property address"
                      openMenuOnClick={false}
                      styles={{
                          control: (provided) => ({ 
                              ...provided, 
                              borderRadius: '0.375rem', 
                              textAlign: 'left', 
                          }),
                          placeholder: (provided) => ({ 
                              ...provided, 
                              textAlign: 'left',
                          }),
                          menu: (provided) => ({ 
                              ...provided, 
                              textAlign: 'left',
                          }),
                          singleValue: (provided) => ({
                              ...provided, 
                              textAlign: 'left',
                          }),
                      }}
                  />
              </Col>
          </Row>
          <Button variant="primary" type="submit" className="w-100 submit-btn enquiry-submit" style={{ backgroundColor: agentDetails.agent_button_colour, borderColor: agentDetails.agent_button_colour }} disabled={isUpdating}>
            {isUpdating ? <Spinner animation="border" size="sm" /> : 'View my property analysis'}
          </Button>
          {message && <Alert variant="secondary" className="mt-3">{message}</Alert>}
          </Form>
      </Container>
    </div>
  );
}

export default PropertySearchLive;