import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Badge from 'react-bootstrap/Badge';
import { Link } from 'react-router-dom';

function MainNav({ agentDetails }) {
  const { logo_url, agent_colour, landing_page_slug, agent_button_colour } = agentDetails;

  return (
    <Navbar expand="lg" style={{ backgroundColor: agent_colour }}>
      <Container style={{ width: '100%' }}>
        <Navbar.Brand as={Link} to={`/${landing_page_slug}`}>
          <img
            src={logo_url}
            height="60"
            className="d-inline-block align-top"
            alt="Agent logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {/* <Nav.Link as={Link} to={`/${agentDetails.landing_page_slug}`}>Home</Nav.Link> */}
            <Nav.Link as={Link} to={`/property-search/${landing_page_slug}`}>Download Reports</Nav.Link>
            <Nav.Link as={Link} to={`/property-search-live/${landing_page_slug}`}>
              Valuation Tool <Badge bg="primary" ref={(el) => {
                if (el) {
                  el.style.setProperty('background-color', agent_button_colour, 'important');
                }
              }}>New</Badge>
            </Nav.Link>
            <Nav.Link as={Link} to={`/suburb-search/${landing_page_slug}`}>Suburb Analysis</Nav.Link>
            {/* <Nav.Link as={Link} to={`/enquiry/${agentDetails.landing_page_slug}`}>Property Appraisal</Nav.Link> */}
            <Nav.Link as={Link} to={`/my-properties/${landing_page_slug}`}>My Properties</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default MainNav;