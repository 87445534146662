import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import EnquiryPage from './pages/EnquiryPage';
// import SuburbPreferences from './pages/SuburbPreferences';
// import PropertyPreferences from './pages/PropertyPreferences';
import PropertySearch from './pages/PropertySearch';
import PropertySearchLive from './pages/PropertySearchLive';
import AgentHome from './pages/AgentHome';
import SuburbSearch from './pages/SuburbSearch';
import SuburbProfile from './pages/SuburbProfile';
import PropertyProfile from './pages/PropertyProfile';
import Unsubscribe from './pages/Unsubscribe';
import MyProperties from './pages/MyProperties';


// Initialize GA4
ReactGA.initialize('G-W7Y0RCBV7Q'); // Replace with your GA4 Measurement ID

if (window.location.hostname === 'localhost') {
  window.api_host = 'https://property-app--production-api-dev.modal.run';
} else {
  window.api_host = 'https://property-app--production-api.modal.run';
}

const TrackPageViews = () => {
  const location = useLocation();

  useEffect(() => {
    const page_path = location.pathname + location.search;
    // console.log(`Logging pageview for ${page_path}`);
    ReactGA.send({ hitType: 'pageview', page: page_path });
  }, [location]);

  return null;
};


function App() {
  
  return (
    <Router>
      <TrackPageViews />
      <Routes>
        <Route path="/:agent_slug" element={<AgentHome />} />
        <Route path="/" element={<AgentHome />} />
        <Route path="/enquiry/:agent_slug" element={<EnquiryPage />} />
        <Route path="/enquiry/" element={<EnquiryPage />} />
        {/* <Route path="/suburb-preferences/:agent_slug" element={<SuburbPreferences />} /> */}
        {/* <Route path="/property-preferences/:agent_slug" element={<PropertyPreferences />} /> */}
        <Route path="/property-search/:agent_slug" element={<PropertySearch />} />
        <Route path="/property-search" element={<PropertySearch />} />
        <Route path="/property-search-live/:agent_slug" element={<PropertySearchLive />} />
        <Route path="/property-search-live" element={<PropertySearchLive />} />
        <Route path="/suburb-search/:agent_slug" element={<SuburbSearch />} />
        <Route path="/suburb-search/" element={<SuburbSearch />} />
        <Route path="/suburb-profile/:suburb/:agent_slug" element={<SuburbProfile />} />
        <Route path="/suburb-profile/:suburb/" element={<SuburbProfile />} />
        <Route path="/property-profile/:property/:agent_slug" element={<PropertyProfile />} />
        <Route path="/property-profile/:property/" element={<PropertyProfile />} />
        <Route path="/unsubscribe/:agent_slug" element={<Unsubscribe />} />
        <Route path="/unsubscribe/" element={<Unsubscribe />} />
        <Route path="/my-properties/:agent_slug" element={<MyProperties />} />
        <Route path="/my-properties/" element={<MyProperties />} />
      </Routes>
    </Router>
  );
}

export default App;