import axios from 'axios';

export const handleTokenAndPostRequest = async (url, data, headers = {}) => {
  try {
    // Get 'jwt_token' from local storage
    let jwt_token = localStorage.getItem('jwt_token');

    // Get 'token' from URL query parameters
    const urlParams = new URLSearchParams(window.location.search);
    const param_token = urlParams.get('token');

    // If 'token' exists in URL, set it in local storage
    if (param_token) {
      localStorage.setItem('param_token', param_token);
    }

    // Get 'param_token' from local storage
    const storedParamToken = localStorage.getItem('param_token');

    // Add 'param_token' to the request body
    const requestBody = {
      ...data,
      param_token: storedParamToken,
      source: window.location.href,
    };

    // Add 'Authorization' header
    const requestHeaders = {
      ...headers,
      'Authorization': `Bearer ${jwt_token}`,
    };

    // Make the POST request
    const response = await axios.post(url, requestBody, { headers: requestHeaders });

    return response;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

export const formatCurrency = (value) => {
  if (value === null || value === undefined) {
    return 'N/A';
  }

  const roundedValue = Math.round(value / 10) * 10;
  if (roundedValue >= 1000000) {
    return `$${(roundedValue / 1000000).toFixed(2)}m`; // Format as millions
  } else if (roundedValue >= 10000) {
    return `$${(roundedValue / 1000).toFixed(0)}k`; // Format as thousands
  } else {
    return new Intl.NumberFormat('en-US', { 
      style: 'currency', 
      currency: 'USD',
      minimumFractionDigits: 0,  // Ensure no decimal places are displayed
      maximumFractionDigits: 0 
    }).format(roundedValue); // Format as hundreds
  }
};