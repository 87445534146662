import React, { useEffect, useState } from 'react';
import { Card, Carousel, Container, Row, Col, Spinner, Alert, Button } from 'react-bootstrap';
import { FaBed, FaBath, FaCar } from 'react-icons/fa';
import MainNav from '../components/MainNav'; // Adjust the import path as needed
import SignInModal from '../components/SignInModal'; // Adjust the import path as needed
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { handleTokenAndPostRequest } from '../utils/utils';

function MyProperties() {
  const { agent_slug: initial_agent_slug } = useParams();
  console.log('initial_agent_slug: ', initial_agent_slug);
  const agent_slug = initial_agent_slug || 'property-insights';
  console.log('agent_slug: ', agent_slug);
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [agentDetails, setAgentDetails] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const param_token = params.get('token');
  const navigate = useNavigate();

  const fetchProperties = async () => {
    const url = `${window.api_host}/my-properties`;
    console.log('agent_slug in fetch: ', agent_slug);
    try {
      const response = await handleTokenAndPostRequest(
        url,
        {
          agent_slug: agent_slug,
        },
        {
          'Content-Type': 'application/json',
        }
      );
      setProperties(response.data);
    } catch (error) {
      setError('Failed to fetch properties');
      setProperties([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProperties();
  }, [agent_slug]);

  useEffect(() => {
    const fetchAgentDetails = async () => {
      const url = `${window.api_host}/agency`;

      try {
        const response = await handleTokenAndPostRequest(
          url,
          {
            agent_slug: agent_slug,
          },
          {
            'Content-Type': 'application/json',
          }
        );
        setAgentDetails(response.data);
      } catch (error) {
        console.error('Error fetching agent details:', error);
      }
    };

    fetchAgentDetails();
  }, [agent_slug]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 576);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (loading) {
    return (
      <div style={{ 
        position: 'absolute', 
        top: '50%', 
        left: '50%', 
        transform: 'translate(-50%, -50%)' 
      }}>
        <Spinner animation="border" />
      </div>
    );
  }

  const handleCardClick = (full_slug) => {
    navigate(`/property-profile/${full_slug}/${agent_slug}`);
  };

  const handleAddPropertyClick = () => {
    navigate(`/property-search-live/${agent_slug}`);
  };

  const handleModalSuccess = () => {
    setModalShow(false);
    fetchProperties(); // Reload properties after successful login
  };

  return (
    <>
      {agentDetails && (
        <div>
          <MainNav agentDetails={agentDetails} />
          <Container style={{ marginTop: '2rem', maxWidth: '800px' }}>
            <Row className="align-items-center mb-3">
              <Col xs={12} className="d-flex justify-content-between align-items-center">
                <h5 style={{ fontSize: '1.5rem', fontWeight: '400' }}>My Properties</h5>
                <Button variant="primary" style={{
                    backgroundColor: agentDetails ? agentDetails.agent_button_colour : '#007bff',
                    borderColor: agentDetails ? agentDetails.agent_button_colour : '#007bff'
                }} onClick={handleAddPropertyClick}>
                  Add Property
                </Button>
              </Col>
              <Col xs={12} style={{ marginTop: '1rem' }}>
                <p>Properties you have viewed, analysed or requested reports for.</p>
              </Col>
            </Row>
            {properties.length === 0 ? (
              <div className="text-center">
                {localStorage.getItem('jwt_token') ? (
                  <p>No properties found. Start adding properties to your list.</p>
                ) : (
                  <>
                    <p>Login to save your viewed properties</p>
                    <Button 
                      variant="primary" 
                      style={{
                        backgroundColor: agentDetails ? agentDetails.agent_button_colour : '#007bff',
                        borderColor: agentDetails ? agentDetails.agent_button_colour : '#007bff'
                      }} 
                      onClick={() => setModalShow(true)}
                    >
                      Login
                    </Button>
                  </>
                )}
              </div>
            ) : (
              <Row>
                {properties.map((property, index) => (
                  <Col key={index} md={12} className="mb-4">
                    <Card 
                      style={{ 
                        cursor: 'pointer', 
                        display: 'flex', 
                        flexDirection: isMobile ? 'column' : 'row', 
                        overflow: 'hidden', 
                        height: '100%' 
                      }}
                      onClick={() => handleCardClick(property.full_slug)}
                    >
                      <div style={{ 
                        width: isMobile ? '100%' : '66.66%', 
                        borderTopLeftRadius: isMobile ? '0.25rem' : '0.25rem', 
                        borderTopRightRadius: isMobile ? '0.25rem' : '0', 
                        borderBottomLeftRadius: isMobile ? '0' : '0.25rem', 
                        borderBottomRightRadius: isMobile ? '0' : '0', 
                        overflow: 'hidden' 
                      }}>
                        <Carousel controls={false} indicators={false} interval={null} style={{ height: '200px' }}>
                          <Carousel.Item>
                            <img
                              className="d-block w-100"
                              src={property.photo_url_full || 'https://realestate-reports-assets.s3.us-west-2.amazonaws.com/static_assets/no-image.jpg'}
                              alt={`Slide ${index}`}
                              style={{ height: '200px', objectFit: 'cover' }}
                            />
                          </Carousel.Item>
                        </Carousel>
                      </div>
                      <div style={{ 
                        display: 'flex', 
                        flexDirection: 'column', 
                        justifyContent: 'space-between', 
                        width: isMobile ? '100%' : '33.33%' 
                      }}>
                        <Card.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                          <Card.Title style={{ fontSize: '1rem' }}>{property.address_text_short}</Card.Title>
                          <Card.Text>
                            <strong>Property Type:</strong> {property.property_type}<br />
                            <FaBed /> {property.bedrooms || 'N/A'} <span style={{ marginRight: '10px' }}></span>
                            <FaBath /> {property.bathrooms || 'N/A'} <span style={{ marginRight: '10px' }}></span>
                            <FaCar /> {property.carspaces || 'N/A'}
                          </Card.Text>
                        </Card.Body>
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>
            )}
          </Container>
        </div>
      )}
      {agentDetails && (
        <SignInModal 
            show={modalShow} 
            onHide={() => setModalShow(false)}
            onSuccess={handleModalSuccess}
            agentDetails={agentDetails}
        />
      )}
    </>
  );
}

export default MyProperties;