import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Form, Row, Col, Alert, Spinner } from 'react-bootstrap';
import { FaAngleLeft } from 'react-icons/fa';
import axios from 'axios';
import { handleTokenAndPostRequest } from '../utils/utils';

function SignInModal(props) {
  const [email, setEmail] = useState('');
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [code, setCode] = useState(Array(6).fill(''));
  const [isCodeValid, setIsCodeValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const emailRef = useRef(null);
  const codeRefs = useRef([]);
  const resetModalState = () => {
    setEmail('');
    setIsCodeSent(false);
    setCode(Array(6).fill(''));
    setIsCodeValid(true);
    setErrorMessage('');
    setIsLoading(false);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleSignIn = async () => {
    if (!validateEmail(email)) {
      setErrorMessage('Please enter a valid email address.');
      return;
    }
  
    setIsLoading(true);
    setErrorMessage('');
    console.log('props.agentDetails.landing_page_slug: ', props.agentDetails.landing_page_slug);
    console.log('props.agentDetails.landing_background_url: ', props.agentDetails.landing_background_url);
    try {
      const response = await handleTokenAndPostRequest(
        `${window.api_host}/send-code-to-email`,
        {
          email: email,
          agent_slug: props.agentDetails.landing_page_slug,
        },
        {
          'Content-Type': 'application/json',
        }
      );
      console.log('Email sent:', response.data);
      setIsCodeSent(true);
      setTimeout(() => codeRefs.current[0].focus(), 100); // Focus on the first input after state update
    } catch (error) {
      console.error('Error sending email:', error);
      setErrorMessage('Failed to send email. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };
  const handleResendCode = async () => {
    setIsLoading(true);
    try {
      const response = await handleTokenAndPostRequest(
        `${window.api_host}/send-code-to-email`,
        {
          email: email,
          agent_slug: props.agentDetails.landing_page_slug,
        },
        {
          'Content-Type': 'application/json',
        }
      );
      console.log('Code resent:', response.data);
      setIsCodeValid(true);
      setErrorMessage('');
      setCode(Array(6).fill('')); // Reset the code input
      setTimeout(() => codeRefs.current[0].focus(), 100); // Focus on the first input after resetting
    } catch (error) {
      console.error('Error resending code:', error);
      setErrorMessage('Failed to resend code. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCodeInput = (value, index) => {
    if (value.length > 1) {
      const newCode = [...code];
      value.split('').forEach((char, idx) => {
        if (index + idx < 6) {
          newCode[index + idx] = char;
        }
      });
      setCode(newCode);
      const nextIndex = index + value.length;
      if (nextIndex < 6) {
        codeRefs.current[nextIndex].focus();
      }
    } else {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);

      // Focus on the next input element
      if (value !== '' && index < 5) {
        codeRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (isCodeSent) {
        validateCode();
      } else {
        handleSignIn();
      }
    } else if (e.key === 'Backspace' && code[index] === '' && index > 0) {
      const newCode = [...code];
      newCode[index - 1] = '';
      setCode(newCode);
      codeRefs.current[index - 1].focus();
    }
  };

  useEffect(() => {
    const allDigitsFilled = code.every(digit => digit !== '');
    if (allDigitsFilled) {
      validateCode();
    }
  }, [code]);

  const validateCode = async () => {
    setIsLoading(true);
    try {
      const response = await handleTokenAndPostRequest(
        `${window.api_host}/validate-code`,
        {
          email: email,
          code: code.join(''),
          agent_slug: props.agentDetails.landing_page_slug,
        },
        {
          'Content-Type': 'application/json',
        }
      );
      if (response.data.valid) {
        console.log('Valid code');
        localStorage.setItem('jwt_token', response.data.token);
        resetModalState();
        props.onHide();
        
        if (props.onSuccess) {
          props.onSuccess(); // Call the analysis function after a successful login
        }
        setIsCodeValid(true);
        
      } else {
        console.log('Invalid code');
        setIsCodeValid(false);
        setErrorMessage('Invalid code. Please try again.');
      }
    } catch (error) {
      console.error('Error validating code:', error);
      setErrorMessage('Failed to validate code. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (props.show && emailRef.current) {
      emailRef.current.focus();
    }
  }, [props.show]);

  useEffect(() => {
    if (!props.show) {
      resetModalState();
    }
  }, [props.show]);

  return (
    <Modal show={props.show} onHide={props.onHide} onExited={resetModalState}>
      <Modal.Header closeButton>
      <Modal.Title>
        {isCodeSent ? (
            <>
            <Button
                variant="link"
                onClick={() => {
                setIsCodeSent(false);
                setErrorMessage(''); // Clear the error message
                setCode(Array(6).fill(''));
                }}
                disabled={isLoading}
                style={{ margin: '0px', marginBottom: '6px', padding: '0px', color: props.buttonColour, fontSize: '1.4rem' }}
            >
                <FaAngleLeft />
            </Button>
            <span style={{ }}>Enter Code</span>
            </>
        ) : 'Sign In'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isCodeSent ? (
          <>
            <p>Email was sent to <b>{email}</b>. Make sure to check your spam or promotions folder if you can't find it.</p>            
            <p>Enter the 6-digit code sent to your email.</p>
            <Row className="justify-content-center">
              {code.map((digit, index) => (
                <Col key={index} xs="auto" style={{ padding: '4px' }}>
                  <Form.Control
                    type="text"
                    maxLength="1"
                    value={digit}
                    onChange={(e) => handleCodeInput(e.target.value, index)}
                    ref={(el) => (codeRefs.current[index] = el)}
                    disabled={isLoading}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    onPaste={(e) => {
                      const paste = e.clipboardData.getData('text');
                      handleCodeInput(paste, index);
                      e.preventDefault();
                    }}
                    style={{ height: '70px', width: '50px', textAlign: 'center', margin: '0px', fontSize: '2rem' }}
                  />
                </Col>
              ))}
            </Row>
            { !isCodeValid && errorMessage !== '' && <Alert variant="danger" style={{ marginTop: '16px', marginBottom: '0px' }}>{errorMessage}</Alert> }            
          </>
        ) : (
          <>
            <p>Sign in with your email to view the full property analysis.</p>
            <Form>
              <Form.Group controlId="formEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  ref={emailRef}
                  disabled={isLoading}
                  onKeyDown={handleKeyDown}
                />
              </Form.Group>
              {errorMessage && errorMessage !== '' && <Alert variant="danger" style={{ marginTop: '16px', marginBottom: '0px' }}>{errorMessage}</Alert>}
            </Form>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        {isCodeSent ? (
          <>
            <Button variant="link" onClick={handleResendCode} disabled={isLoading} style={{ width: '100%'}}>Resend Code</Button>          
            <Button variant="primary" onClick={validateCode} disabled={isLoading} style={{ width: '100%', backgroundColor: props.agentDetails.agent_button_colour, borderColor: props.agentDetails.agent_button_colour }}>
              {isLoading ? <><Spinner animation="border" size="sm" /> <span>Create Account</span></> : 'Create Account'}
            </Button>
          </>
        ) : (
          <>
            <Button variant="primary" onClick={handleSignIn} disabled={isLoading} style={{ width: '100%', backgroundColor: props.agentDetails.agent_button_colour, borderColor: props.agentDetails.agent_button_colour }}>
              {isLoading ? <><Spinner animation="border" size="sm" /> <span>Sign In</span></> : 'Sign In'}
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default SignInModal;