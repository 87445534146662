import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';
import { Container, Row, Col, Form, Button, ButtonGroup, Image, Spinner, ProgressBar, Alert, Tabs, Tab, Card, Nav, Breadcrumb, OverlayTrigger, Tooltip, Carousel, FormCheck} from 'react-bootstrap';
import MainNav from '../components/MainNav';
import SignInModal from '../components/SignInModal';
import ValueDisplayGroup from '../components/ValueDisplayGroup';
import Plot from 'react-plotly.js';
import { APIProvider, Map, AdvancedMarker, InfoWindow, useAdvancedMarkerRef, Pin } from '@vis.gl/react-google-maps';
import './Styles.css';
import { FaCheckCircle, FaTimesCircle, FaInfoCircle, FaStar, FaBed, FaBath, FaCar, FaArrowUp, FaArrowDown,FaChartLine, FaBalanceScale, FaHistory, FaCheck, FaSearch } from 'react-icons/fa';
import { handleTokenAndPostRequest, formatCurrency } from '../utils/utils';

import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { set } from 'lodash';
import ComparableProperties from '../components/ComparableProperties';

const propertyTypeOptions = [
    { value: 'House', label: 'House' },
    { value: 'Apartment', label: 'Apartment' },
    { value: 'Townhouse', label: 'Townhouse' },
    { value: 'Vacant Land', label: 'Vacant Land' },
    { value: 'Villa', label: 'Villa' },
    { value: 'Acreage Semi Rural', label: 'Acreage Semi Rural' },
    { value: 'Rural', label: 'Rural' },
    { value: 'Semi Detached', label: 'Semi Detached' },
    { value: 'Duplex', label: 'Duplex' },
    { value: 'New House Land', label: 'New House Land' },
    { value: 'New Apartments', label: 'New Apartments' },
    { value: 'Block Of Units', label: 'Block Of Units' },
    { value: 'Studio', label: 'Studio' },
    { value: 'Terrace', label: 'Terrace' }
];


const PropertyProfile = ({ propertyId }) => {
    const [agentDetails, setAgentDetails] = useState(null);
    const [propertyData, setPropertyData] = useState(null);
    const [propertyAnalysisData, setPropertyAnalysisData] = useState(null);
    const { property, agent_slug: initial_agent_slug } = useParams();
    const agent_slug = initial_agent_slug || 'property-insights';
    const [suburb, setSuburb] = useState(null);
    const [postcode, setPostcode] = useState(null);
    const [bedrooms, setBedrooms] = useState(null);
    const [bathrooms, setBathrooms] = useState(null);
    const [carspaces, setCarspaces] = useState(null);    
    const [propertyType, setPropertyType] = useState(propertyTypeOptions[0]);
    const [landArea, setLandArea] = useState(null);
    // default for area is null
    const [internalArea, setInternalArea] = useState(null);
    const [propertyNotes, setPropertyNotes] = useState(null);
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [state, setState] = useState(null);
    
    const [analysedBedrooms, setAnalysedBedrooms] = useState(null);
    const [analysedBathrooms, setAnalysedBathrooms] = useState(null);
    const [analysedCarspaces, setAnalysedCarspaces] = useState(null);    
    const [analysedPropertyType, setAnalysedPropertyType] = useState(propertyTypeOptions[0]);
    const [analysedLandArea, setAnalysedLandArea] = useState(null);

    const[analysisNeeded, setAnalysisNeeded] = useState(false);

    const [showAlert, setShowAlert] = useState(true);
    const [selectedFeatures, setSelectedFeatures] = useState([]);
    const [tabKey, setTabKey] = useState('estimates');
    const [analysisLoading, setAnalysisLoading] = useState(false);
    const [propertyLoading, setPropertyLoading] = useState(false);
    const [recentSoldData, setRecentSoldData] = useState(null);

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);
    const [modalShow, setModalShow] = useState(false);
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const param_token = params.get('token');
    const jwt_token = localStorage.getItem('jwt_token');

    const animatedComponents = makeAnimated();

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 576);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);    

    useEffect(() => {
        if (agentDetails) {
            document.title = `${agentDetails.agency_name_short} Property Report`;
        }
    }, [agentDetails]);

    useEffect(() => {
        const fetchAgentDetails = async () => {
          const url = `${window.api_host}/agency`;  // Adjusted to remove specific slug from URL path
      
          try {
            const response = await handleTokenAndPostRequest(
              url,
              {
                agent_slug: agent_slug,  // Include agent_slug in the body
                property: property,
              },
              {
                'Content-Type': 'application/json',
              }
            );
      
            setAgentDetails(response.data);
          } catch (error) {
            console.error('Error:', error);
          }
        };
      
        fetchAgentDetails();
    }, [agent_slug, property]);


    useEffect(() => {
        if (!propertyLoading) {
            setPropertyLoading(true);
            const fetchData = async () => {
                try {
                // take the last part of the URL after the last - character
                    const property_id = property.split('-').pop();
                    const response = await handleTokenAndPostRequest(
                        `${window.api_host}/property`,
                        {
                          property_id: property_id,
                          agent_slug: agent_slug,
                        },
                        {
                          'Content-Type': 'application/json',
                        }
                    );
                    setPropertyData(response.data);
                    setBedrooms(response.data.bedrooms);
                    setLandArea(response.data.land_area);
                    setBathrooms(response.data.bathrooms);
                    setCarspaces(response.data.carspaces);
                    setLatitude(response.data.latitude);
                    setLongitude(response.data.longitude);
                    setState(response.data.state); 
                    setPropertyType(response.data.listing_property_type);
                    setSuburb(response.data.suburb_id);
                    setPostcode(response.data.postcode);
                
                } catch (error) {
                    console.error("Error fetching /property data", error);
                } finally {
                    setPropertyLoading(false);
                }
            };
            fetchData();
        }
    }, [propertyId]);

    useEffect(() => {
        if (!analysisLoading) {
            const fetchData = async () => {
                try {
                    // last part of url
                    const property_id = property.split('-').pop();
                    setAnalysisLoading(true);
                    const sold_date = propertyData.sold_date;
                    const sold_price = propertyData.sold_price;
                    const rented_date = propertyData.rented_date;
                    const rented_price = propertyData.rented_price;
                    const response = await handleTokenAndPostRequest(
                        `${window.api_host}/property-analysis`,
                        {
                          property_id: property_id,
                          bedrooms: bedrooms,
                          bathrooms: bathrooms,
                          carspaces: carspaces,
                          internal_area: internalArea,
                          land_area: landArea,
                          property_type: propertyType,
                          features: selectedFeatures.map(feature => feature.value),
                          property_notes: propertyNotes,
                          last_sold_date: sold_date,
                          last_sold_price: sold_price,
                          last_rented_date: rented_date,
                          last_rented_price: rented_price,
                          state: state,
                          postcode: postcode,
                          latitude: latitude,
                          longitude: longitude,
                          agent_slug: agent_slug,
                        },
                        {
                          'Content-Type': 'application/json',
                        }
                    );
                    setPropertyAnalysisData(response.data);

                    setAnalysedBathrooms(bathrooms);
                    setAnalysedBedrooms(bedrooms);
                    setAnalysedCarspaces(carspaces);
                    setAnalysedLandArea(landArea);
                    setAnalysedPropertyType(propertyType);

                    setAnalysisNeeded(false);

                } catch (error) {
                    console.error("Error fetching property analysis data", error);
                } finally {
                    setAnalysisLoading(false);
                }
            };
            fetchData();
        }
    }, [propertyData]); 


    useEffect(() => {
        console.log("HERE", propertyType, analysedPropertyType);
        if (bedrooms != analysedBedrooms || bathrooms != analysedBathrooms || carspaces != analysedCarspaces || landArea != analysedLandArea || propertyType != analysedPropertyType) {
            setAnalysisNeeded(true);
        }else{
            setAnalysisNeeded(false);
        }

    }, [bedrooms, bathrooms, carspaces, propertyType, landArea]);


    const handleAnalyseProperty = async () => {
        try {
            const jwt_token = localStorage.getItem('jwt_token');
            if (!jwt_token) {
                setModalShow(true);
                return;
            }
        
            setTabKey('estimates');
            setAnalysisLoading(true);
        
            const property_id = property.split('-').pop();
            const response = await handleTokenAndPostRequest(
                `${window.api_host}/property-analysis`,
                {
                  property_id: property_id,
                  bedrooms: bedrooms,
                  bathrooms: bathrooms,
                  carspaces: carspaces,
                  internal_area: internalArea,
                  land_area: landArea,
                  property_type: propertyType,
                  features: selectedFeatures.map(feature => feature.value),
                  property_notes: propertyNotes,
                  last_sold_date: propertyData.sold_date,
                  last_sold_price: propertyData.sold_price,
                  state: state,
                  postcode: postcode,
                  latitude: latitude,
                  longitude: longitude,
                  agent_slug: agent_slug,
                },
                {
                  'Content-Type': 'application/json',
                }
            );
        
            setPropertyAnalysisData(response.data);
            setAnalysedBathrooms(bathrooms);
            setAnalysedBedrooms(bedrooms);
            setAnalysedCarspaces(carspaces);
            setAnalysedLandArea(landArea);
            setAnalysedPropertyType(propertyType);
            setAnalysisNeeded(false);
        } catch (error) {
            console.error("Error fetching property analysis data", error);
        } finally {
            setAnalysisLoading(false);
        }
    };

    const handleModalSuccess = () => {
    setModalShow(false);
    handleAnalyseProperty();
    }

    useEffect(() => {
        if (agentDetails) {
            document.title = `${agentDetails.agency_name_short} Property Report`;
            }
        }, [agentDetails]);

        if (!agentDetails || !propertyData) {
        return (
            <div style={{ 
            position: 'absolute', 
            top: '50%', 
            left: '50%', 
            transform: 'translate(-50%, -50%)' 
            }}>
            <Spinner animation="border" />
            </div>
        );
    }


    const formatPercentChange = (value) => {
        const formattedValue = Math.abs(value);
        if (formattedValue === 0) {
            return 0;
        }
        // if null then return 'N/A'
        if (value === null) {
            return 'N/A';
        }
        return formattedValue;
    };
    
        
    const featureOptions = [
        { value: 'SecureParking', label: 'Secure Parking' },
        { value: 'BuiltInWardrobes', label: 'Built In Wardrobes' },
        { value: 'AirConditioning', label: 'Air Conditioning' },
        { value: 'Ensuite', label: 'Ensuite' },
        { value: 'Gas', label: 'Gas' },
        { value: 'Heating', label: 'Heating' },
        { value: 'Dishwasher', label: 'Dishwasher' },
        { value: 'Floorboards', label: 'Floorboards' },
        { value: 'Study', label: 'Study' },
        { value: 'SwimmingPool', label: 'Swimming Pool' },
        { value: 'Bath', label: 'Bath' },
        { value: 'InternalLaundry', label: 'Internal Laundry' },
        { value: 'Shed', label: 'Shed' },
        { value: 'Intercom', label: 'Intercom' },
        { value: 'FullyFenced', label: 'Fully Fenced' },
        { value: 'NorthFacing', label: 'North Facing' },
        { value: 'SolarPanels', label: 'Solar Panels' },
        { value: 'GroundFloor', label: 'Ground Floor' },
        { value: 'AlarmSystem', label: 'Alarm System' },
        { value: 'Gym', label: 'Gym' },
        { value: 'BalconyDeck', label: 'Balcony Deck' },
        { value: 'PetsAllowed', label: 'Pets Allowed' },
        { value: 'GardenCourtyard', label: 'Garden Courtyard' },
        { value: 'WaterViews', label: 'Water Views' },
        { value: 'Furnished', label: 'Furnished' },
        { value: 'CityViews', label: 'City Views' },
        { value: 'SeparateDiningRoom', label: 'Separate Dining Room' },
        { value: 'RainwaterStorageTank', label: 'Rainwater Storage Tank' },
        { value: 'BroadbandInternetAccess', label: 'Broadband Internet Access' },
        { value: 'CableOrSatellite', label: 'Cable Or Satellite' },
        { value: 'TennisCourt', label: 'Tennis Court' },
        { value: 'SolarHotWater', label: 'Solar Hot Water' },
        { value: 'DoubleGlazedWindows', label: 'Double Glazed Windows' },
        { value: 'WallCeilingInsulation', label: 'Wall Ceiling Insulation' },
        { value: 'OutdoorSpa', label: 'Outdoor Spa' },
        { value: 'IndoorSpa', label: 'Indoor Spa' },
        { value: 'EnergyEfficientAppliances', label: 'Energy Efficient Appliances' },
        { value: 'WaterEfficientAppliances', label: 'Water Efficient Appliances' },
        { value: 'GreywaterSystem', label: 'Greywater System' },
        { value: 'WaterEfficientFixtures', label: 'Water Efficient Fixtures' }
    ];

    const PropertyMetric = ({
        title,
        metric_definition,
        lowerValue,
        midValue,
        upperValue,
        percentChangeLastMonth,
        percentChangeYearAgo,
        percentChange3YearsAgo,
        percentChangeLastTransacted,

        estimateLastMonth,
        estimateYearAgo,
        estimate3YearsAgo,
        lastTransactedValue,
        lastTransactedDate,
        lastTransactedLabel,

        midColor,
        time_series_date,
        time_series_value,
        yxaxis_label,
        is_currency,
        showTimeSeries
    }) => {
        const margin_bottom_p = '0.25rem';
    
        return (
            <div style={{ marginBottom: '3rem', padding: '0.5rem', border: '0' }}>
                <h5 style={{ fontSize: '1.5rem', fontWeight: '400' }}>{title}</h5>
                <p>{metric_definition}</p>
                <div style={{ padding: 0 }}>
                    <ValueDisplayGroup
                        lowerValue={lowerValue}
                        midValue={midValue}
                        upperValue={upperValue}
                        midColor={midColor}
                        showTitle={false}
                    />
                    {showTimeSeries && (
                    
                    <div style={{ marginTop: '1rem' }}>
                        <Row style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                            <Col xs={6} sm={6} md={3}>
                                <Card style={{ margin: '0.5rem 0', textAlign: 'center' }}>
                                    <Card.Body>
                                        <div style={{ display: 'inline-block', cursor: 'pointer', marginBottom: '0.4rem' }}>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                <Tooltip id="tooltip-comparison">
                                                    Last Month: {formatCurrency(estimateLastMonth)}
                                                    <br />
                                                    Current: {(midValue)}
                                                </Tooltip>
                                                }
                                            >
                                                <p style={{ marginBottom: margin_bottom_p, color: midColor, borderBottom: '1px dotted black', display: 'inline' }}>
                                                From Last Month:
                                                </p>
                                            </OverlayTrigger>
                                        </div>
                                        <strong style={{ color: (percentChangeLastMonth) > 0 ? 'green' : (percentChangeLastMonth) < 0 ? 'red' : 'gray', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        {(percentChangeLastMonth) > 0 ? (
                                            <>
                                                <FaArrowUp style={{ color: 'green', marginRight: '0.3rem' }} />
                                                {formatPercentChange(percentChangeLastMonth)}%
                                            </>
                                        ) : (percentChangeLastMonth) < 0 ? (
                                            <>
                                                <FaArrowDown style={{ color: 'red', marginRight: '0.3rem' }} />
                                                {formatPercentChange(percentChangeLastMonth)}%
                                            </>
                                        ) : (
                                            <>
                                                {formatPercentChange(percentChangeLastMonth)}%
                                            </>
                                        )}
                                    </strong>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={6} sm={6} md={3}>
                                <Card style={{ margin: '0.5rem 0', textAlign: 'center' }}>
                                    <Card.Body>
                                        <div style={{ display: 'inline-block', cursor: 'pointer', marginBottom: '0.4rem' }}>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                <Tooltip id="tooltip-comparison">
                                                    1 Year Ago: {formatCurrency(estimateYearAgo)}
                                                    <br />
                                                    Current: {(midValue)}
                                                </Tooltip>
                                                }
                                            >
                                                <p style={{ marginBottom: margin_bottom_p, color: midColor, borderBottom: '1px dotted black', display: 'inline' }}>
                                                From 1 Year Ago:
                                                </p>
                                            </OverlayTrigger>
                                        </div>                                        
                                        
                                        <strong style={{ color: (percentChangeYearAgo) > 0 ? 'green' : (percentChangeYearAgo) < 0 ? 'red' : 'gray', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            {(percentChangeYearAgo) > 0 ? (
                                                <>
                                                    <FaArrowUp style={{ color: 'green', marginRight: '0.3rem' }} />
                                                    {formatPercentChange(percentChangeYearAgo)}%
                                                </>
                                            ) : (percentChangeYearAgo) < 0 ? (
                                                <>
                                                    <FaArrowDown style={{ color: 'red', marginRight: '0.3rem' }} />
                                                    {formatPercentChange(percentChangeYearAgo)}%
                                                </>
                                            ) : (
                                                <>
                                                    {formatPercentChange(percentChangeYearAgo)}%
                                                </>
                                            )}
                                        </strong>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={6} sm={6} md={3}>
                                <Card style={{ margin: '0.5rem 0', textAlign: 'center' }}>
                                    <Card.Body>
                                        <div style={{ display: 'inline-block', cursor: 'pointer', marginBottom: '0.4rem' }}>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                <Tooltip id="tooltip-comparison">
                                                    3 Years Ago: {formatCurrency(estimate3YearsAgo)}
                                                    <br />
                                                    Current: {(midValue)}
                                                </Tooltip>
                                                }
                                            >
                                                <p style={{ marginBottom: margin_bottom_p, color: midColor, borderBottom: '1px dotted black', display: 'inline' }}>
                                                From 3 Years Ago:
                                                </p>
                                            </OverlayTrigger>
                                        </div>                                           
                                        
                                        <strong style={{ color: (percentChange3YearsAgo) > 0 ? 'green' : (percentChange3YearsAgo) < 0 ? 'red' : 'gray', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            {(percentChange3YearsAgo) > 0 ? (
                                                <>
                                                    <FaArrowUp style={{ color: 'green', marginRight: '0.3rem' }} />
                                                    {formatPercentChange(percentChange3YearsAgo)}%
                                                </>
                                            ) : (percentChange3YearsAgo) < 0 ? (
                                                <>
                                                    <FaArrowDown style={{ color: 'red', marginRight: '0.3rem' }} />
                                                    {formatPercentChange(percentChange3YearsAgo)}%
                                                </>
                                            ) : (
                                                <>
                                                    {formatPercentChange(percentChange3YearsAgo)}%
                                                </>
                                            )}
                                        </strong>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={6} sm={6} md={3}>
                                <Card style={{ margin: '0.5rem 0', textAlign: 'center' }}>
                                    <Card.Body>
                                        <div style={{ display: 'inline-block', cursor: 'pointer', marginBottom: '0.4rem' }}>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                <Tooltip id="tooltip-comparison">                                                    
                                                    {lastTransactedLabel} Date: {lastTransactedDate ? lastTransactedDate.split('T')[0] : "N/A"}
                                                    <br />
                                                    {lastTransactedLabel} Price: {lastTransactedValue ? formatCurrency(lastTransactedValue) : "N/A"}
                                                    <br />
                                                    Current: {midValue}
                                                </Tooltip>
                                                }
                                            >
                                                <p style={{ marginBottom: margin_bottom_p, color: midColor, borderBottom: '1px dotted black', display: 'inline' }}>
                                                From {lastTransactedLabel}:
                                                </p>
                                            </OverlayTrigger>
                                        </div>                                           
                                                                                
                                        {percentChangeLastTransacted === null ? (
                                            <strong style={{ color: 'gray', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                N/A
                                            </strong>
                                        ) : (
                                            <strong style={{ color: (percentChangeLastTransacted) > 0 ? 'green' : (percentChangeLastTransacted) < 0 ? 'red' : 'gray', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                {(percentChangeLastTransacted) > 0 ? (
                                                    <>
                                                        <FaArrowUp style={{ color: 'green', marginRight: '0.3rem' }} />
                                                        {formatPercentChange(percentChangeLastTransacted)}%
                                                    </>
                                                ) : (percentChangeLastTransacted) < 0 ? (
                                                    <>
                                                        <FaArrowDown style={{ color: 'red', marginRight: '0.3rem' }} />
                                                        {formatPercentChange(percentChangeLastTransacted)}%
                                                    </>
                                                ) : (
                                                    <>
                                                        {formatPercentChange(percentChangeLastTransacted)}%
                                                    </>
                                                )}
                                            </strong>
                                        )}
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    )}
                    {showTimeSeries && (
                    <Plot
                        data={[
                            {
                                x: time_series_date,
                                y: time_series_value,
                                type: 'scatter',
                                mode: 'lines+markers',
                                marker: { color: agentDetails.agent_colour },
                            },
                        ]}
                        layout={{
                            xaxis: {
                                title: 'Month',
                                tickformat: '%Y-%m',
                                type: 'date',
                            },
                            yaxis: {
                                title: yxaxis_label,
                                tickprefix: is_currency ? '$' : '',
                                range: [0, Math.max(...time_series_value) * 1.1],
                            },
                            margin: {
                                l: 70,
                                r: 30,
                                t: 40,
                                b: 70,
                                pad: 0
                            },
                            autosize: true
                        }}
                        style={{ width: '100%', padding: '0px' }}
                        config={{ displayModeBar: false }}
                    />
                    )}
                </div>
            </div>
        );
    };

    const renderTabTitle = (Icon, title) => (
        <span style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'center', textAlign: 'center', justifyContent: 'center' }}>
            <Icon style={{ marginBottom: isMobile ? '0.25rem' : '0', marginRight: isMobile ? '0' : '0.5rem', fontSize: isMobile ? '1rem' : '1.2rem' }} />
            <span style={{fontSize: isMobile ? '0.6rem' : '1rem' }}>{title}</span>
        </span>
    );


    const AnimatedProgressBar = () => {
        const [now, setNow] = useState(0);
    
        useEffect(() => {
            const interval = setInterval(() => {
                setNow(prev => {
                    if (prev < 90) {
                        return prev + 1.5; // 90 / (5000ms / 100ms)
                    }
                    clearInterval(interval);
                    return 90;
                });
            }, 100);
    
            return () => clearInterval(interval); // cleanup on component unmount
        }, []);
    
        return (
            <div style={{ display: 'flex', justifyContent: 'center', minHeight: '600px', flexDirection: 'column', alignItems: 'center' }}>
                <ProgressBar animated now={now} style={{ width: '50%', }} variant="light"/>
            </div>
        );
    }

    return (
        <div>
        <MainNav agentDetails={agentDetails} />
            {agentDetails && (  
            <SignInModal 
                show={modalShow} 
                onHide={() => setModalShow(false)}
                onSuccess={handleModalSuccess}
                agentDetails={agentDetails}
            />
            )}
            <Container style={{ marginTop: '70px', maxWidth: '900px' }}>
                {propertyData && (
                    <Carousel style={{ borderRadius: '10px', width: '100%', maxWidth: '900px', margin: '0 auto', marginBottom: '20px' }}>
                        {propertyData.media_urls.map((url, index) => (
                            <Carousel.Item key={index}>
                                <img
                                    className="d-block w-100"
                                    src={url}
                                    alt={`${propertyData.address_text_short} - ${index + 1}`}
                                />
                            </Carousel.Item>
                        ))}
                    </Carousel>    
                )}           
                <h3 style={{ marginBottom: '20px' }}>{propertyData.address_text_short}</h3>
                
            </Container>
        
            <Container style={{ marginTop: '20px', maxWidth: '900px',  minHeight: '600px'}}>
            <Tabs activeKey={tabKey} onSelect={(k) => setTabKey(k)} className="mb-3" fill>
            <Tab eventKey="estimates" title={renderTabTitle(FaChartLine, "Estimates")}>
            <h5 style={{ fontSize: '1.5rem', fontWeight: '400' }}>Estimation Tool</h5>
            <p>Perform your own appraisal using our valuation tool. The more data you add in, the more accurate your estimates will be.</p>

            {showAlert && (
                    <Alert variant="warning" onClose={() => setShowAlert(false)} dismissible>
                        Note: If there are missing or incorrect property details, please update them below before analysing the property.
                    </Alert>
                )}
                {agentDetails && propertyData && (
                    <>
                        <Row xs={2} sm={1} md={3} className="g-4">
                            <Col>
                                <Form.Label>
                                    <FaBed /> Bedrooms: {bedrooms === null ? 'Unknown' : bedrooms}
                                </Form.Label>
                                <Form.Range 
                                    min={0} 
                                    max={10} 
                                    value={bedrooms} 
                                    onChange={(e) => setBedrooms(e.target.value)} 
                                    style={{ 
                                        '--thumb-color': agentDetails.agent_colour,
                                        '--thumb-width': isMobile ? '22px' : '20px', // Increase thumb size on mobile
                                        '--thumb-height': isMobile ? '22px' : '20px' // Increase thumb size on mobile
                                    }}
                                />                        
                            </Col>
                            <Col>
                                <Form.Label>
                                    <FaBath /> Bathrooms: {bathrooms === null ? 'Unknown' : bathrooms}
                                </Form.Label>
                                <Form.Range 
                                    min={0} 
                                    max={10} 
                                    value={bathrooms} 
                                    onChange={(e) => setBathrooms(e.target.value)} 
                                    style={{ 
                                        '--thumb-color': agentDetails.agent_colour,
                                        '--thumb-width': isMobile ? '22px' : '20px', // Increase thumb size on mobile
                                        '--thumb-height': isMobile ? '22px' : '20px' // Increase thumb size on mobile
                                    }}
                                />
                            </Col>
                            <Col>
                                <Form.Label>
                                    <FaCar /> Carspaces: {carspaces === null ? 'Unknown' : carspaces}
                                </Form.Label>
                                <Form.Range 
                                    min={0} 
                                    max={10} 
                                    value={carspaces} 
                                    onChange={(e) => setCarspaces(e.target.value)} 
                                    style={{ 
                                        '--thumb-color': agentDetails.agent_colour,
                                        '--thumb-width': isMobile ? '22px' : '20px', // Increase thumb size on mobile
                                        '--thumb-height': isMobile ? '22px' : '20px' // Increase thumb size on mobile
                                    }}
                                />
                            </Col>
                            <Col>
                                <Form.Label>Property Type</Form.Label>
                                { propertyType && (
                                    <Select 
                                        options={propertyTypeOptions} 
                                        components={animatedComponents}
                                        closeMenuOnSelect={true}
                                        styles={{
                                            // Add custom styles if needed
                                        }}
                                        onChange={e => setPropertyType(e.value)}
                                        value={propertyTypeOptions.find(option => option.value === propertyType)}
                                        defaultValue={propertyTypeOptions.find(option => option.value === propertyType)}
                                    />
                                )}               
                            </Col>
                            {/* <Col>
                                <Form.Label>Internal Area (m²)</Form.Label>
                                <Form.Control 
                                    type="number" 
                                    value={internalArea} 
                                    onChange={(e) => setInternalArea(e.target.value)} 
                                    placeholder="Internal Area (m²)" 
                                />
                            </Col> */}
                            <Col>
                                <Form.Label>Land Area (m²)</Form.Label>
                                <Form.Control 
                                    type="number" 
                                    value={landArea} 
                                    onChange={(e) => setLandArea(e.target.value)} 
                                    placeholder="Land Area (m²)" 
                                />
                            </Col>
                        </Row>
                        {/* <div style={{ marginTop: '24px' }}>
                            <Form.Label>Features</Form.Label>
                            <Select 
                                options={featureOptions} 
                                isMulti
                                components={animatedComponents}
                                closeMenuOnSelect={false}
                                blurInputOnSelect={false}
                                styles={{
                                }}
                                onChange={setSelectedFeatures}
                                value={selectedFeatures}
                            />
                        </div>
                        <Row xs={1} sm={1} md={1} className="g-4" style={{ marginTop: '0px' }}>
                            <Col>
                                <Form.Label>Property Description</Form.Label>
                                
                                <Form.Control 
                                    as="textarea" 
                                    rows={3} 
                                    value={propertyNotes}
                                    onChange={(e) => setPropertyNotes(e.target.value)}
                                    placeholder="Add your own notes on the condition of the property. We will use your notes to adjust the valuation calculation. Eg: Renovated kitchen, new tile flooring installed, poor condition interior etc." 
                                />
                            </Col>
                        </Row> */}
                        {/* Analyse Property Button */}
                        <Button 
                            variant="primary" 
                            style={{ backgroundColor: agentDetails.agent_button_colour, borderColor: agentDetails.agent_button_colour, marginTop: '20px', width: '100%' }}
                            onClick={handleAnalyseProperty}
                            disabled={analysisLoading || !analysisNeeded}
                        >
                            {analysisLoading ? (
                                <span>
                                    <Spinner animation="border" size="sm" style={{ marginRight: '5px' }}/> Loading...
                                </span>
                            ) : (
                                !analysisNeeded ? (
                                    <span>
                                        <FaCheck style={{ marginRight: '5px' }} /> Analysis Complete
                                    </span>
                                ) : (
                                    <span>
                                        <FaSearch style={{ marginRight: '5px' }} /> Analyse Property
                                    </span>
                                )
                            )}
                        </Button>
                    </>
                )}                
                {analysisLoading ? (
                    // <div style={{ display: 'flex', justifyContent: 'center', minHeight: '600px', flexDirection: 'column', alignItems: 'center' }}>
                    //     <Spinner animation="border" />
                    // </div>
                    <AnimatedProgressBar />                    
                ) : (
                    propertyAnalysisData && agentDetails && (
                        <div style={{ fontSize: isMobile ? '0.8rem' : 'initial', marginTop: '20px' }}>
                                <PropertyMetric
                                    title="Valuation Estimate"
                                    metric_definition="The estimated value of the property based on recent comparable sales data."
                                    lowerValue={formatCurrency(propertyAnalysisData.valuation_lower)}
                                    midValue={formatCurrency(propertyAnalysisData.valuation)}
                                    upperValue={formatCurrency(propertyAnalysisData.valuation_upper)}
                                    midColor={agentDetails.agent_button_colour}
                                    time_series_date={propertyAnalysisData.time_series.reporting_month}
                                    time_series_value={propertyAnalysisData.time_series.valuation_estimate}
                                    yxaxis_label={'Valuation Estimate ($)'}
                                    is_currency={true}
                                    percentChangeLastMonth={propertyAnalysisData.valuation_percent_change_last_month}
                                    percentChangeYearAgo={propertyAnalysisData.valuation_percent_change_year_ago}
                                    percentChange3YearsAgo={propertyAnalysisData.valuation_percent_change_3_years_ago}
                                    percentChangeLastTransacted={propertyAnalysisData.valuation_percent_change_last_transacted}
                                    estimateLastMonth={propertyAnalysisData.valuation_1_month}
                                    estimateYearAgo={propertyAnalysisData.valuation_1_year}
                                    estimate3YearsAgo={propertyAnalysisData.valuation_3_years}
                                    lastTransactedValue={propertyData.sold_price}
                                    lastTransactedDate={propertyData.sold_date}
                                    lastTransactedLabel="Last Sold"             
                                    showTimeSeries={true}
                                />
                                <PropertyMetric
                                    title="Days to Sell Estimate"
                                    metric_definition="The estimated number of days it would take to sell the property based on recent comparable sales data."
                                    lowerValue={propertyAnalysisData.days_to_sell_lower}
                                    midValue={propertyAnalysisData.days_to_sell}
                                    upperValue={propertyAnalysisData.days_to_sell_upper}
                                    midColor={agentDetails.agent_button_colour}
                                    yxaxis_label={'Days to Sell Estimate'}
                                    is_currency={false}
                                    showTimeSeries={false}
                                />
                                <PropertyMetric
                                    title="Rental Estimate"
                                    metric_definition="The estimated weekly rental income for the property based on recent comparable rental data."
                                    lowerValue={formatCurrency(propertyAnalysisData.rental_estimate_lower)}
                                    midValue={formatCurrency(propertyAnalysisData.rental_estimate)}
                                    upperValue={formatCurrency(propertyAnalysisData.rental_estimate_upper)}
                                    midColor={agentDetails.agent_button_colour}
                                    time_series_date={propertyAnalysisData.time_series.reporting_month}
                                    time_series_value={propertyAnalysisData.time_series.rental_estimate} 
                                    yxaxis_label={'Rental Estimate ($)'}
                                    is_currency={true}
                                    percentChangeLastMonth={propertyAnalysisData.rental_estimate_percent_change_last_month}
                                    percentChangeYearAgo={propertyAnalysisData.rental_estimate_percent_change_year_ago}
                                    percentChange3YearsAgo={propertyAnalysisData.rental_estimate_percent_change_3_years_ago}
                                    percentChangeLastTransacted={propertyAnalysisData.rental_estimate_percent_change_last_transacted}
                                    estimateLastMonth={propertyAnalysisData.rental_estimate_1_month}
                                    estimateYearAgo={propertyAnalysisData.rental_estimate_1_year}
                                    estimate3YearsAgo={propertyAnalysisData.rental_estimate_3_years}
                                    lastTransactedValue={propertyData.rented_price}
                                    lastTransactedDate={propertyData.rented_date}
                                    lastTransactedLabel="Last Rented"
                                    showTimeSeries={true}
                                />
                                
                                {/* <PropertyMetric
                                    title="Days to Lease"
                                    metric_definition="The estimated number of days it would take to lease the property based on recent comparable rental data."
                                    lowerValue={propertyAnalysisData.days_to_lease_lower}
                                    midValue={propertyAnalysisData.days_to_lease}
                                    upperValue={propertyAnalysisData.days_to_lease_upper}
                                    midColor={agentDetails.agent_button_colour}
                                    time_series_date={propertyAnalysisData.time_series.reporting_month}
                                    time_series_value={propertyAnalysisData.time_series.days_to_lease}
                                    yxaxis_label={'Days to Lease'}
                                    is_currency={false}   
                                    showTimeSeries={false}                      
                                /> */}
                        </div>
                    )
                )}
            </Tab>

            <Tab eventKey="comparables" title={renderTabTitle(FaBalanceScale, "Comparables")}>    
                { propertyData && agentDetails && (
                    <ComparableProperties
                    suburb={propertyData.suburb_id}
                    agentDetails={agentDetails}
                    title="Comparable Properties"
                    subtitle={`Choose the most similar comparable properties to ${propertyData.address_text_short} to find your comparables estimate.`} 
                    />
                )}
            </Tab>
            <Tab eventKey="history" title={renderTabTitle(FaHistory, "History")}>
                {propertyData && propertyData.history ? (
                    propertyData.history.length > 0 ? (
                    <>
                    <div style={{ marginBottom: '3rem', padding: '0.5rem', border: '0', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
                        <h5 style={{ fontSize: '1.5rem', fontWeight: '400' }}>Property History</h5>
                        <p>Historical sales and rental data for the property.</p>
                        <br />
                        <ul className="timeline" style={{ borderLeftColor: agentDetails.agent_colour }}>
                            {propertyData.history.map((event, index) => (
                            <li key={index} className="timeline-item mb-5" style={{ '--dot-color': agentDetails.agent_colour }}>
                                <span className="timeline-icon" style={{ backgroundColor: agentDetails.agent_colour }}>
                                    
                                </span>
                                <span className="badge" style={{ marginBottom: '10px', backgroundColor: agentDetails.agent_colour, color: agentDetails.agent_button_colour, borderRadius: '50px', padding: '5px 10px'}}>
                                    {event.type}
                                </span>                                                        
                                <h5 className="fw-bold">
                                ${event.price !== null ? event.price.toLocaleString() : 'N/A'}
                                <span style={{ fontSize: '0.8rem', color: '#7e8594' }}> {event.label}</span>
                                </h5>
                                <p className="text-muted mb-2 fw-bold">
                                    {event.date !== null ? new Date(event.date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) : 'Unknown Date'}
                                </p>
                                <p className="text-muted"></p>
                            </li>
                            ))}
                        </ul>
                    </div>
                    </>
                    ) : (
                        <p className="text-muted">No history available.</p>
                    )
                ) : null}
            </Tab>
        </Tabs>
            </Container>

        </div>
        
    )
};

export default PropertyProfile;