import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';
import { Container, Row, Col, Form, Button, Image, Spinner, Alert, Tabs, Tab, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import MainNav from '../components/MainNav';
import Plot from 'react-plotly.js';

import { APIProvider, Map, AdvancedMarker, InfoWindow, useAdvancedMarkerRef, Pin } from '@vis.gl/react-google-maps';
import './Styles.css';

import { FaCheckCircle, FaTimesCircle, FaStar } from 'react-icons/fa';
import ComparableProperties from '../components/ComparableProperties';
import { handleTokenAndPostRequest } from '../utils/utils';

const SuburbProfile = ({ suburbId }) => {
    const [suburbData, setSuburbData] = useState(null);
    const [suburbMonthlyData, setSuburbMonthlyData] = useState(null);
    const [suburbPropertyTypeData, setSuburbPropertyTypeData] = useState(null);
    const [rentSuburbPropertyTypeData, setRentSuburbPropertyTypeData] = useState(null);
    const [soldSuburbPropertyTypeData, setSoldSuburbPropertyTypeData] = useState(null);
    const { suburb, agent_slug: initial_agent_slug } = useParams();
    const agent_slug = initial_agent_slug || 'property-insights';
    const [agentDetails, setAgentDetails] = useState(null);

    useEffect(() => {
        if (agentDetails) {
            document.title = `${agentDetails.agency_name_short} Property Report`;
        }
    }, [agentDetails]);

    useEffect(() => {
        const fetchAgentDetails = async () => {
          const url = `${window.api_host}/agency`;
      
          try {
            const response = await handleTokenAndPostRequest(
              url,
              {
                agent_slug: agent_slug
              },
              {
                'Content-Type': 'application/json',
              }
            );
            setAgentDetails(response.data);
          } catch (error) {
            console.error('Error:', error);
          }
        };
      
        fetchAgentDetails();
    }, [agent_slug]);


    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await handleTokenAndPostRequest(
              `${window.api_host}/suburb`,
              {
                suburb: suburb,
                agent_slug: agent_slug,
              },
              {
                'Content-Type': 'application/json',
              }
            );
            setSuburbData(response.data);
          } catch (error) {
            console.error("Error fetching suburb data", error);
          }
        };
      
        fetchData();
    }, [suburbId]);
    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await handleTokenAndPostRequest(
              `${window.api_host}/suburb-property-type-last-month-summary`,
              {
                suburb: suburb,
                agent_slug: agent_slug,
              },
              {
                'Content-Type': 'application/json',
              }
            );
            const data = typeof response.data === 'string' ? JSON.parse(response.data) : response.data;
      
            setSuburbPropertyTypeData(data);
            setSoldSuburbPropertyTypeData(data['Sold']);
            setRentSuburbPropertyTypeData(data['Rent']);
          } catch (error) {
            console.error("Error fetching suburb data", error);
          }
        };
      
        fetchData();
    }, [suburbId]);
    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await handleTokenAndPostRequest(
              `${window.api_host}/suburb-monthly`,
              {
                suburb: suburb,
                agent_slug: agent_slug,
              },
              {
                'Content-Type': 'application/json',
              }
            );
            const data = typeof response.data === 'string' ? JSON.parse(response.data) : response.data;
            setSuburbMonthlyData(data);
          } catch (error) {
            console.error("Error fetching suburb data", error);
          }
        };
      
        fetchData();
    }, [suburbId]);

    useEffect(() => {
        if (agentDetails) {
          document.title = `${agentDetails.agency_name_short} Property Report`;
        }
      }, [agentDetails]);

    if (!agentDetails || !suburbData || !suburbPropertyTypeData || !suburbMonthlyData) {
    return (
        <div style={{ 
        position: 'absolute', 
        top: '50%', 
        left: '50%', 
        transform: 'translate(-50%, -50%)' 
        }}>
        <Spinner animation="border" />
        </div>
    );
    }

    const {
        median_sold_days_on_market_filled,
        median_sold_price_filled,
        prior_median_sold_days_on_market,
        prior_median_sold_price,
        prior_sold_properties,
        median_sold_days_on_market_percent_change_text,
        median_sold_price_percent_change_text,
        sold_properties_percent_change_text,
        sold_properties,
        
        median_rent_days_on_market_filled,
        median_rent_price_filled,
        prior_median_rent_days_on_market,
        prior_median_rent_price,
        prior_rent_properties,
        median_rent_days_on_market_percent_change_text,
        median_rent_price_percent_change_text,
        rent_properties_percent_change_text,
        rent_properties,
        reporting_month,
        suburb_postcode,
        suburb_summary,
        suburb_overview_summary,
    } = suburbData;
    

    const median_sold_price_display = `$${median_sold_price_filled.toLocaleString('en-US')}`;
    const sold_days_on_market_delta = ((median_sold_days_on_market_filled - prior_median_sold_days_on_market) / prior_median_sold_days_on_market * 100).toFixed(0);
    const sold_price_delta = ((median_sold_price_filled - prior_median_sold_price) / prior_median_sold_price * 100).toFixed(0);
    const sold_properties_delta = ((sold_properties - prior_sold_properties) / prior_sold_properties * 100).toFixed(0);

    const median_rent_price_display = `$${median_rent_price_filled.toLocaleString('en-US')}`;
    const rent_days_on_market_delta = ((median_rent_days_on_market_filled - prior_median_rent_days_on_market) / prior_median_rent_days_on_market * 100).toFixed(0);
    const rent_price_delta = ((median_rent_price_filled - prior_median_rent_price) / prior_median_rent_price * 100).toFixed(0);
    const rent_properties_delta = ((rent_properties - prior_rent_properties) / prior_rent_properties * 100).toFixed(0);


    const GOOGLE_MAPS_API_KEY = "AIzaSyC38mj1vIAktLfp8a568dcEfU5Ud-rVvzk";
    
    const colors = {
        'Rural': '#1f77b4',
        'New House Land': '#ff7f0e',
        'Acreage Semi Rural': '#2ca02c',
        'Villa': '#d62728',
        'Townhouse': '#9467bd',
        'Vacant Land': '#8c564b',
        'Semi Detached': '#e377c2',
        'House': '#7f7f7f',
        'Apartment': '#bcbd22',
        'Duplex': '#17becf'
      };
      
      // Function to format data for Plotly
      const formatDataForPlotlyBar = (data, column_to_plot) => {
        
        // check if data is not undefined
        if (!data) {
            return [];
        }
        // Get unique property types
        const propertyTypes = [...new Set(data.map(item => item.property_type_display))];
        
        return propertyTypes.map(propertyType => {
          const filteredData = data.filter(item => item.property_type_display === propertyType);
          
          return {
            x: filteredData.map(item => item.sold_month),
            y: filteredData.map(item => item[column_to_plot]),
            type: 'bar',
            name: propertyType
          };
        });
      };
      const formatDataForPlotly = (data, column_to_plot) => {
        
        // check if data is not undefined
        if (!data) {
            return [];
        }
        // Get unique property types
        const propertyTypes = [...new Set(data.map(item => item.property_type_display))];
        
        return propertyTypes.map(propertyType => {
          const filteredData = data.filter(item => item.property_type_display === propertyType);
          
          return {
            x: filteredData.map(item => item.sold_month),
            y: filteredData.map(item => item[column_to_plot]),
            type: 'scatter',
            mode: 'lines+markers',
            name: propertyType
          };
        });
      };

    const RatingStars = ({ rating }) => (
        [...Array(5)].map((star, i) => (
        <FaStar key={i} color={i < rating ? "#ffc107" : "#e4e5e9"} />
        ))
    );
    
    const RatingComponent = ({ data, explanations }) => (
        <Container className="d-flex justify-content-center" style={{ marginBottom: '50px' }}>
          <Card style={{ maxWidth: '300px' }} className="w-100 text-center">
            <Card.Body>
              {Object.entries(data).map(([key, value]) => (
                <Row key={key} className="mb-3" style={{ justifyContent: "space-between", display: "flex" }}>
                  <Col className="text-start" style={{ cursor: 'pointer' }}>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id={`tooltip-${key}`}>{explanations[key]}</Tooltip>}
                    >
                      <span style={{ borderBottom: '1px dotted black' }}>{key}</span>
                    </OverlayTrigger>
                  </Col>
                  <Col className="text-end"><RatingStars rating={value} /></Col>
                </Row>
              ))}
            </Card.Body>
          </Card>
        </Container>
      );
    return (
    <div>
        <MainNav agentDetails={agentDetails} />
        {agentDetails && suburbData && (
            <div 
                id='suburb-profile-hero' 
                style={{ 
                height: '40vh',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                backgroundColor: '#f0f0f0', // Fallback background color.
                backgroundImage: `url(${agentDetails.landing_background_url})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                backgroundAttachment: window.innerWidth > 768 ? 'fixed' : 'scroll', // Use 'scroll' for mobile.
                backgroundSize: 'cover',
                }}
            >
                <p 
                style={{ 
                    marginBottom: '15px', 
                    fontSize: 'calc(20px + 4vw)'
                }}
                >
                {suburbData.suburb_initcap}
                </p>
                <p 
                style={{ 
                    marginBottom: '15px', 
                    fontSize: 'calc(10px + 1.5vw)' 
                }}
                >
                {suburbData.state}, {suburbData.postcode}
                </p>
            </div>
            )}
        <Container className="suburb-profile-page" style={{ paddingTop: '40px', maxWidth: '800px' }}>
            {suburbData && (
                <div>
                    <h2>Suburb Overview</h2>
                    <p>{suburb_summary}</p>
                    <RatingComponent data={suburbData.ratings} explanations={suburbData.rating_explanations} />
                    <h5>Upsides</h5>
                    <ul style={{ listStyleType: 'none', paddingLeft: '2rem', paddingRight: '2rem' }}>
                        {suburbData.upsides.map((upside, index) => {
                            const [title, ...description] = upside.split(':');
                            return (
                                <li key={index}>
                                    <FaCheckCircle style={{ color: 'green', marginRight: '8px' }} />
                                    <strong>{title}:</strong>{description.join(':')}
                                </li>
                            );
                        })}
                    </ul>
                    <h5>Downsides</h5>
                    <ul style={{ listStyleType: 'none', paddingLeft: '2rem', paddingRight: '2rem' }}>
                        {suburbData.downsides.map((downside, index) => {
                            const [title, ...description] = downside.split(':');
                            return (
                                <li key={index}>
                                    <FaTimesCircle style={{ color: 'red', marginRight: '8px' }} />
                                    <strong>{title}:</strong>{description.join(':')}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            )}
            <h2>Summary Last Month</h2>
            
            <Row className="justify-content-md-center" style={{ alignItems: 'center' }}>
                <p>{suburb_overview_summary}</p>
                <h5>Sale Market</h5>
                <Col xs={12} md={4}>
                    <div style={{ textAlign: 'center' }}>
                        <span>Median Days on Market</span>
                        <h1>{median_sold_days_on_market_filled}</h1>
                        <div style={{ color: median_sold_days_on_market_percent_change_text === '0%' ? 'gray' : sold_days_on_market_delta > 0 ? 'green' : 'red' }}>
                            <strong>{median_sold_days_on_market_percent_change_text}</strong>
                        </div>
                    </div>
                </Col>
                <Col xs={12} md={4} style={{ padding: '30px 0' }}>
                    <div style={{ textAlign: 'center' }}>
                        <span>Sold Properties</span>
                        <h1>{sold_properties}</h1>
                        <div style={{ color: sold_properties_percent_change_text === '0%' ? 'gray' : sold_properties_delta > 0 ? 'green' : 'red' }}>
                            <strong>{sold_properties_percent_change_text}</strong>
                        </div>
                    </div>
                </Col>
                <Col xs={12} md={4}>
                    <div style={{ textAlign: 'center' }}>
                        <span>Median Sold Price</span>
                        <h1>{median_sold_price_display}</h1>
                        <div style={{ color: median_sold_price_percent_change_text === '0%' ? 'gray' : sold_price_delta > 0 ? 'green' : 'red' }}>
                            <strong>{median_sold_price_percent_change_text}</strong>
                        </div>
                    </div>
                </Col>
            </Row>            
            <Row className="justify-content-md-center" style={{ alignItems: 'center' }}>
                <h5>Rental Market</h5>
                <Col xs={12} md={4}>
                    <div style={{ textAlign: 'center' }}>
                        <span>Median Days on Market</span>
                        <h1>{median_rent_days_on_market_filled}</h1>
                        <div style={{ color: median_rent_days_on_market_percent_change_text === '0%' ? 'gray' : rent_days_on_market_delta > 0 ? 'green' : 'red' }}>
                            <strong>{median_rent_days_on_market_percent_change_text}</strong>
                        </div>
                    </div>
                </Col>
                <Col xs={12} md={4} style={{ padding: '30px 0' }}>
                    <div style={{ textAlign: 'center' }}>
                        <span>Rented Properties</span>
                        <h1>{rent_properties}</h1>
                        <div style={{ color: rent_properties_percent_change_text === '0%' ? 'gray' : rent_properties_delta > 0 ? 'green' : 'red' }}>
                            <strong>{rent_properties_percent_change_text}</strong>
                        </div>
                    </div>
                </Col>
                <Col xs={12} md={4}>
                    <div style={{ textAlign: 'center' }}>
                        <span>Median Rent Price</span>
                        <h1>{median_rent_price_display}</h1>
                        <div style={{ color: median_rent_price_percent_change_text === '0%' ? 'gray' : rent_price_delta > 0 ? 'green' : 'red' }}>
                            <strong>{median_rent_price_percent_change_text}</strong>
                        </div>
                    </div>
                </Col>
            </Row>       
            <p>Note: Median sold price reflects the suburb as a whole and may not match the uniqueness of your property. For a more accurate valuation estimate for your property, click below.</p>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                    <Button as={Link} to={`/property-search/${agentDetails.landing_page_slug}`} variant="primary" className="submit-btn" style={{ backgroundColor: agentDetails.agent_button_colour, borderColor: agentDetails.agent_button_colour }}>
                        Download Instant Property Report
                    </Button>
                </div>                     
            <br />
            <br />
            <h2>Suburb Property Type Summary Last Month</h2>
            <p>The data below summarises the results over the last month by property type and bedrooms.</p>
            <Tabs defaultActiveKey="sale" fill>  
                <Tab eventKey="sale" title="Sale Market">
                    {!soldSuburbPropertyTypeData || soldSuburbPropertyTypeData.length === 0 ? (
                    <div>
                        <p>No sold properties last month</p>
                        <br />
                    </div>
                    ) : (
                    <div style={{ overflowX: 'auto' }}>
                        
                        <table style={{ borderCollapse: 'collapse', width: '100%', fontFamily: 'Arial, sans-serif',  marginTop: '2em'  }}>
                        <thead style={{ backgroundColor: 'transparent' }}>
                            <tr>
                            <th style={{ textAlign: 'center', padding: '8px', fontWeight: 'bold', fontSize: '0.6em', color: '#333', background: 'transparent', borderBottom: '1px solid #dddddd' }}>
                                PROPERTY TYPE
                            </th>
                            <th style={{ textAlign: 'center', padding: '8px', fontWeight: 'bold', fontSize: '0.6em', color: '#333', background: 'transparent', borderBottom: '1px solid #dddddd' }}>
                                BEDS
                            </th>
                            <th style={{ textAlign: 'center', padding: '8px', fontWeight: 'bold', fontSize: '0.6em', color: '#333', background: 'transparent', borderBottom: '1px solid #dddddd' }}>
                                SOLD PRICE
                            </th>
                            <th style={{ textAlign: 'center', padding: '8px', fontWeight: 'bold', fontSize: '0.6em', color: '#333', background: 'transparent', borderBottom: '1px solid #dddddd' }}>
                                SOLD LAST MONTH
                            </th>
                            </tr>
                        </thead>
                        <tbody>
                            {soldSuburbPropertyTypeData.map((property, index) => (
                            <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#ffffff' : '#fafafa' }}>
                                <td style={{ border: '1px solid #dddddd', textAlign: 'center', padding: '8px', fontSize: '0.8em', color: '#333', whiteSpace: 'nowrap' }}>
                                {property.property_type_display}
                                </td>
                                <td style={{ border: '1px solid #dddddd', textAlign: 'center', padding: '8px', fontSize: '0.8em', color: '#333', whiteSpace: 'nowrap' }}>
                                {property.bedrooms_string}
                                </td>
                                <td style={{ border: '1px solid #dddddd', textAlign: 'center', padding: '8px', fontSize: '0.8em', color: '#333', whiteSpace: 'nowrap' }}>
                                {property.display_price_short}
                                </td>
                                <td style={{ border: '1px solid #dddddd', textAlign: 'center', padding: '8px', fontSize: '0.8em', color: '#333', whiteSpace: 'nowrap' }}>
                                {property.properties_last_month}
                                </td>
                            </tr>
                            ))}
                        </tbody>
                        </table>
                    </div>
                    )}
                </Tab>
                <Tab eventKey="rent" title="Rental Market">
                    {!rentSuburbPropertyTypeData || rentSuburbPropertyTypeData.length === 0 ? (
                    <div>
                        <p>No rented properties last month</p>
                        <br />
                    </div>
                    ) : (
                    <div style={{ overflowX: 'auto' }}>

                        <table style={{ borderCollapse: 'collapse', width: '100%', fontFamily: 'Arial, sans-serif', fontSize: '100%', marginTop: '2em'  }}>
                        <thead style={{ backgroundColor: 'transparent' }}>
                            <tr>
                            <th style={{ textAlign: 'center', padding: '8px', fontWeight: 'bold', fontSize: '0.6em', color: '#333', background: 'transparent', borderBottom: '1px solid #dddddd' }}>
                                PROPERTY TYPE
                            </th>
                            <th style={{ textAlign: 'center', padding: '8px', fontWeight: 'bold', fontSize: '0.6em', color: '#333', background: 'transparent', borderBottom: '1px solid #dddddd' }}>
                                BEDS
                            </th>
                            <th style={{ textAlign: 'center', padding: '8px', fontWeight: 'bold', fontSize: '0.6em', color: '#333', background: 'transparent', borderBottom: '1px solid #dddddd' }}>
                                RENTED PRICE
                            </th>
                            <th style={{ textAlign: 'center', padding: '8px', fontWeight: 'bold', fontSize: '0.6em', color: '#333', background: 'transparent', borderBottom: '1px solid #dddddd' }}>
                                RENTED LAST MONTH
                            </th>
                            </tr>
                        </thead>
                        <tbody>
                            {rentSuburbPropertyTypeData.map((property, index) => (
                            <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#ffffff' : '#fafafa' }}>
                                <td style={{ border: '1px solid #dddddd', textAlign: 'center', padding: '8px', fontSize: '0.8em', color: '#333', whiteSpace: 'nowrap' }}>
                                {property.property_type_display}
                                </td>
                                <td style={{ border: '1px solid #dddddd', textAlign: 'center', padding: '8px', fontSize: '0.8em', color: '#333', whiteSpace: 'nowrap' }}>
                                {property.bedrooms_string}
                                </td>
                                <td style={{ border: '1px solid #dddddd', textAlign: 'center', padding: '8px', fontSize: '0.8em', color: '#333', whiteSpace: 'nowrap' }}>
                                {property.display_price_short}
                                </td>
                                <td style={{ border: '1px solid #dddddd', textAlign: 'center', padding: '8px', fontSize: '0.8em', color: '#333', whiteSpace: 'nowrap' }}>
                                {property.properties_last_month}
                                </td>
                            </tr>
                            ))}
                        </tbody>
                        </table>
                        <br />
                    </div>
                    )}
                </Tab>
            </Tabs>
            <br />
            <br />
            <h2>Median Prices Over Time</h2>
            <p>{suburbMonthlyData.median_sold_price_filled_summary}</p>
            {suburbMonthlyData && agentDetails && ( 
                <Row className="justify-content-md-center">
                    <Col>
                        <div style={{ overflowX: 'auto' }}>
                        <Plot
                            data={formatDataForPlotly(suburbMonthlyData.data, 'median_sold_price_filled')}
                            layout={{
                                xaxis: {
                                title: 'Month',
                                tickformat: '%Y-%m',
                                type: 'date',
                                },
                                yaxis: {
                                title: 'Median Sold Price ($)',
                                tickprefix: '$',
                                range: [0, Math.max(...suburbMonthlyData?.data?.flatMap(d => d.median_sold_price_filled) || [0])],
                                },
                                autosize: true,
                            }}
                            config={{ displayModeBar: false }}
                            useResizeHandler={true}
                            style={{ width: '100%' }}
                        />
                        </div>
                    </Col>
                    
                </Row>
            )}
            <br />
            <br />
            <h2>Median Days on Market Over Time</h2>
            <p>{suburbMonthlyData.median_sold_days_on_market_filled_summary}</p>
            {suburbMonthlyData && agentDetails && (
                <Row className="justify-content-md-center">
                    <Col>
                        <div style={{ overflowX: 'auto' }}>
                        <Plot
                            data={formatDataForPlotly(suburbMonthlyData.data, 'median_sold_days_on_market_filled')}
                            layout={{
                                xaxis: {
                                title: 'Month',
                                tickformat: '%Y-%m',
                                type: 'date',
                                },
                                yaxis: {
                                title: 'Median Days on Market',
                                range: [0, Math.max(...suburbMonthlyData?.data?.flatMap(d => d.median_sold_days_on_market_filled) || [0])],
                                },
                                autosize: true,
                            }}
                            config={{ displayModeBar: false }}
                            useResizeHandler={true}
                            style={{ width: '100%' }}
                        />                   
                        </div>
                    </Col>
                </Row>
            )}
            <br />
            <br />
            <h2>Sold Properties Over Time</h2>
            <p>{suburbMonthlyData.sold_properties_summary}</p>
            {suburbMonthlyData && agentDetails && (
                <Row className="justify-content-md-center">
                    <Col>
                        <div style={{ overflowX: 'auto' }}>
                        <Plot
                            data={formatDataForPlotlyBar(suburbMonthlyData.data, 'sold_properties')}
                            layout={{
                                barmode: 'stack',
                                xaxis: {
                                title: 'Month',
                                tickformat: '%Y-%m',
                                type: 'date',
                            },
                                yaxis: {
                                title: 'Sold Properties',
                                range: [0, Math.max(...suburbMonthlyData?.data?.flatMap(d => d.sold_properties) || [0])],
                                },
                                autosize: true,
                            }}
                            config={{ displayModeBar: false }}
                            useResizeHandler={true}
                            style={{ width: '100%' }}
                        />                       
                        </div>
                    </Col>
                </Row>
            )}
                    <div>
                        { suburbData && agentDetails && (
                            <ComparableProperties
                            suburb={suburbData.suburb_id}
                            agentDetails={agentDetails}
                            title="Market Activity"
                            />
                        )}
                    </div>
                    <br/>
                    <br/>
                    <br/>

                </Container>
            </div>
    );
};

export default SuburbProfile;