import React, { useEffect, useState, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import MainNav from '../components/MainNav';

import { Container, Row, Col, Form, Button, Image, Alert } from 'react-bootstrap';
import './Styles.css';
import { Spinner } from 'react-bootstrap';

import { validateEmail } from '../utils/validate';
import { handleTokenAndPostRequest } from '../utils/utils';

function EnquiryPage() {
  const { agent_slug: initial_agent_slug } = useParams();
  const agent_slug = initial_agent_slug || 'property-insights';
  const [agentDetails, setAgentDetails] = useState(null);
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [name, setName] = useState('');
  const [enquiry, setEnquiry] = useState('');
  const [message, setMessage] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [leasingChecked, setLeasingChecked] = useState(false);
  const [sellingChecked, setSellingChecked] = useState(false);

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  useEffect(() => {
    if (agentDetails) {
      document.title = `${agentDetails.agency_name_short} Enquiry Form`;
    }
  }, [agentDetails]);
  useEffect(() => {
    if (agentDetails) {
        document.body.style.backgroundImage = `url(${agentDetails.landing_background_url})`;
        document.body.style.backgroundRepeat = 'no-repeat';
        document.body.style.backgroundPosition = 'center center';
        document.body.style.backgroundAttachment = 'fixed';
        document.body.style.backgroundSize = 'cover';
    }

    return () => {
        document.body.style.backgroundImage = null;
    };
  }, [agentDetails]);
  useEffect(() => {
    const fetchAgentDetails = async () => {
      const url = `${window.api_host}/agency`;
  
      try {
        const response = await handleTokenAndPostRequest(
          url,
          {
            agent_slug: agent_slug,
          },
          {
            'Content-Type': 'application/json',
          }
        );
        setAgentDetails(response.data);
      } catch (error) {
        console.error('Error:', error);
      }
    };
  
    fetchAgentDetails();
  }, [agent_slug]);

  useEffect(() => {
    if (agentDetails) {
      setEmail(agentDetails.user_email);
    }
  }, [agentDetails]);  

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (!validateEmail(e.target.value)) {
      // set error message
    } else {
      // clear error message
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!email || !phone) {
      setMessage('Please submit both your email and phone number so we can get in touch.');
      return;
    }
    setIsUpdating(true);
  
    try {
      const response = await handleTokenAndPostRequest(
        `${window.api_host}/enquiry`,
        {
          email,
          message: enquiry,
          phone: phone,
          address,
          name,
          agency_id: agentDetails.agency_id,
          agent_id: agentDetails.agent_id,
          leasing: leasingChecked,
          selling: sellingChecked,
          agent_slug: agentDetails.landing_page_slug,
        },
        {
          'Content-Type': 'application/json',
        }
      );
  
      console.log(response.data);
      setIsUpdating(false);
      setMessage('Thank you, we will get back to you shortly.');
    } catch (error) {
      console.error('Error:', error);
      setIsUpdating(false);
      setMessage('There was an error submitting your enquiry. Please try again.');
    }
  };

  if (!agentDetails) {
    return (
      <div style={{ 
        position: 'absolute', 
        top: '50%', 
        left: '50%', 
        transform: 'translate(-50%, -50%)' 
        }}>
        <Spinner animation="border" />
      </div>
    );
  }

  return (
    <div>
    <MainNav agentDetails={agentDetails} />
    <Container className="agent-page" style={{ maxWidth: '600px' }}>
      <Row className="justify-content-md-center">
      <Col md="auto">
        <h1>Request a property appraisal from {agentDetails.agency_name_short}</h1>
        <p>Fill in your details below to request a free property appraisal. We will provide you with a detailed report on the value of your property and help you get ready for selling.</p>
        </Col>
      </Row>
      <Form className="w-100" onSubmit={handleSubmit}>
      <Row className="mb-2" style={{ textAlign: 'left' }}>
        <Col>
          <Form.Check type="checkbox" label="Leasing my property" checked={leasingChecked} onChange={() => setLeasingChecked(!leasingChecked)} style={{ display: 'inline-block', marginRight: '20px' }} />
          <Form.Check type="checkbox" label="Selling my property" checked={sellingChecked} onChange={() => setSellingChecked(!sellingChecked)} style={{ display: 'inline-block' }} />
        </Col>
      </Row>
      <Row className="mb-2">
            <Col>
            <Form.Control className="w-100" placeholder="Name" value={name} onChange={e => setName(e.target.value)}/>
            </Col>
        </Row>        
       
        <Row className="mb-2">
            <Col>
            <Form.Control className="w-100" type="email" placeholder="Email" value={email} onChange={handleEmailChange} />
            </Col>
        </Row>
        <Row className="mb-2">
            <Col>
            <Form.Control className="w-100" type="tel" placeholder="Phone" value={phone} onChange={e => setPhone(e.target.value)} />
            </Col>
        </Row>

        <Row className="mb-2">
            <Col>
            <Form.Control className="w-100" placeholder="Address" value={address} onChange={e => setAddress(e.target.value)}/>
            </Col>
        </Row>
        <Row className="mb-2">
            <Col>
            <Form.Control className="w-100" as="textarea" placeholder="Optional message" style={{ height: '100px' }} value={enquiry} onChange={e => setEnquiry(e.target.value)} />
            </Col>
        </Row>
        <Button variant="primary" type="submit" className="w-100 submit-btn enquiry-submit" style={{ backgroundColor: agentDetails.agent_button_colour, borderColor: agentDetails.agent_button_colour }}>
        {isUpdating ? <Spinner animation="border" size="sm" /> : 'Submit'}
        </Button>
        {message && <Alert variant="secondary" className="mt-3">{message}</Alert>}
        </Form>
    </Container>
    </div>
  );
}

export default EnquiryPage;